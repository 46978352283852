<template>
  <section class="banner-with-content">
    <NuxtImg
      :src="contentImage.image"
      alt="imagem da banner"
      class="banner-with-content__img"
      loading="lazy"
      sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:1920px"
      quality="100"
    />

    <div class="container banner-with-content__content">
      <div class="col-12 col-md-6 banner-with-content__content__text">
        <h3 class="font-bold" v-html="contentImage.title" />
        <p v-html="contentImage.content" />
        <slot name="extra-content" />
        <VButton v-if="contentImage.button" class="banner-with-content__content__button" :to="contentImage.button.link">
          <span class="font-bold" v-html="$t(contentImage.button.content)">
          </span>
          <VIconArrow :color="contentImage.button.color" />
        </VButton>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
interface IContent {
  image: string
  title: string
  content: string
  positionImageMobile?: string
  button?: {
    color: string
    background: string
    content: string
    link?: string
  }
  paddingBanner?: string
}

export default {
  props: {
    contentImage: {
      type: Object as PropType<IContent>,
      required: true,
    },
    textFontStyle: {
      type: String,
      default: 'normal normal normal 16px/24px Montserrat'
    },
    paddingBanner: {
      type: String,
      default: '100px 0px'
    }
  },

  computed: {
    colorButton(): string | undefined {
      return this.contentImage?.button?.color
    },

    backgroundButton(): string | undefined {
      return this.contentImage?.button?.background
    },

    paddingBanner(): string | undefined {
      return this.contentImage.paddingBanner ? this.contentImage.paddingBanner : '100px 0px'
    },

    objectPositionImageMobile(): string | undefined {
      return this.contentImage.positionImageMobile
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-with-content {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: v-bind(paddingBanner);
  position: relative;

  &__img {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media (max-width: $mobile) {
      object-position: v-bind(objectPositionImageMobile);
    }
  }

  &__content {
    width: 100%;

    h3 {
      font: normal normal 900 40px/49px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
    }

    p {
      text-align: left;
      margin: 20px 0px !important;
      font: v-bind(textFontStyle);
      letter-spacing: 0px;
      color: #ffffff;
      font-weight: 100 !important;
    }

    &__button {
      text-align: center;
      font: normal normal 900 16px/19px Montserrat;
      letter-spacing: 0px;
      height: 50px;
      border: none;
      max-width: 400px;
      display: flex;
      align-items: center;
      gap: 7px;
      color: v-bind(colorButton);
      background: v-bind(backgroundButton);
    }
  }
}

@media screen and (max-width: $mobile) {
  .banner-with-content {
    padding: 0px 20px;
    background-position: 50% !important;

    &__content {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 40px;

      h3 {
        font: normal normal 900 20px/24px Montserrat;
      }

      p {
        padding: 0px;
        font: normal normal normal 13px/18px Montserrat;
      }

      &__button {
        margin-top: 22px;
        width: 100%;
        padding: 10px;
        font: normal normal 900 13px/16px Montserrat;
        height: auto;
      }
    }
  }
}
</style>
